<template>
    <AModalContent :show-close="true" class="send-trait">
        <div class="send-trait-title">
            Sending
            <span>{{ item.name }} #{{ item.id }}</span>
        </div>
        <form class="send-trait-form" method="#" @submit.prevent="onSubmit">
            <div class="send-trait-input">
                <label>Destination Wallet</label>
                <input v-model="wallet" class="app-input" name="wallet">
            </div>
            <div class="send-trait-input">
                <label>Amount</label>
                <input
                    v-model.number="amount"
                    type="number"
                    min="1"
                    :max="maxAmount"
                    class="app-input"
                    name="amount"
                >
            </div>

            <button class="send-trait-button" :class="{ disabled: isSending }" type="submit">
                <img
                    v-if="isSending"
                    class="loader"
                    src="../../assets/img/spinner-clear.svg"
                    width="20"
                > Send
            </button>
        </form>
    </AModalContent>
</template>
<script>
import { useToast } from "@/composables/useToast"
import { sendTraits } from "@/functions/sendTraits"
import { useWeb3Store } from "@/store/web3"

import AModalContent from "../Modal/AModalContent.vue"
export default {
    props: {
        item: {
            type: Object,
            default: () => ({})
        },
        maxAmount: Number,
        onSuccess: Function
    },
    components: { AModalContent },

    data: () => ({
        amount: 1,
        wallet: '',
        isSending: false
    }),
    methods: {
        async onSubmit() {
            try {
                this.isSending = true
                if (this.amount < 1 || this.amount > this.maxAmount) {
                    useToast().error({ title: "Please enter valid amount" })
                    return
                }
                await sendTraits(this.wallet, [
                    {
                        id: this.item.id,
                        amount: this.amount
                    }
                ])
                this.onSuccess && this.onSuccess()
                this.$emit('close')
            } catch (err) {
                console.log(err)
            }
            this.isSending = false
        }
    },

}
</script>
<style lang="scss">
.send-trait {
    width: 400px;
    max-width: 90%;
    z-index: 10;
    background-color: var(--gray-light);
    border-radius: 10px;
    padding: 20px;

    &-title {
        font-weight: bold;
        font-size: 20px;
        margin-bottom: 20px;
        span {
            color: var(--primary);
        }
    }
    &-form {
    }

    &-input {
        text-align: left;
        margin-bottom: 15px;
        label {
            text-transform: uppercase;
            opacity: 0.5;
            font-weight: bold;
            font-size: 12px;
            margin-bottom: 5px;
            display: block;
        }
        input {
            background-color: transparent;
            padding: 5px 10px;
        }
    }
    &-button {
        background-color: var(--primary);
        width: 100%;
        display: block;
        border-radius: 5px;
        border: none;
        color: #fff;
        text-transform: uppercase;
        font-weight: bold;
        padding: 10px;
        cursor: pointer;
        margin-top: 20px;
        transition: 0.3s;
        display: flex;
        align-items: center;
        justify-content: center;
        &.disabled {
            pointer-events: none;
            opacity: 0.5;
        }
        img {
            margin-right: 5px;
        }

        &:hover {
            filter: brightness(0.8);
        }
    }
}
</style>