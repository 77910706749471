import { useApesStorageApi } from "@/api/useApesStorageApi";
import { useToast } from "@/composables/useToast";
import { Config } from "@/config";
import { useMethBankStore } from "@/store/meth-bank";
import { web3 } from "@/web3";
import ENS from "ethereum-ens";
import { useWeb3Store } from "../store/web3";
import { shorten } from "@/helpers/utils";
const messageToSign = `I'm transferring from my Trait Bank`;
// traits - {id, amount}
export const sendTraits = async function (destination, traits) {
  const web3Store = useWeb3Store();
  // resolve ENS address
  let wallet = destination;
  const isENS = !wallet.startsWith("0x") || wallet.endsWith(".eth");
  if (isENS) {
    const { network } = Config;
    const ens = new ENS(network.rpcUrl.replace("sepolia", "mainnet"));
    try {
      wallet = await ens.resolver(wallet).addr();
    } catch (err) {
      console.log("Fail resolving ENS", err);
    }
  }
  let signature;
  if (!signature) {
    try {
      signature = await web3.eth.personal.sign(
        messageToSign,
        web3Store.walletAddress
      );
    } catch (err) {
      useToast().error({ title: "Couldn't sign the message" });
      console.error(err);
    }
  }
  if (!signature) return false;

  const res = await useApesStorageApi({
    throw: true,
    toast: { error: true },
  }).exec({
    url: "/wallet/trait-transfer",
    method: "POST",
    data: {
      sender: web3Store.collectionWallet,
      recipient: wallet,
      amounts: traits.map((t) => t.amount),
      ids: traits.map((t) => t.id),
      signer: web3Store.walletAddress, // wallet that sign the request, if it's not same as sender, safe claim will be checked
      signature, // signature to confirm the request
      network: Config.network.name,
    },
  });
  useToast().success({
    title: "Success",
    text: `You successfully transfered trait${
      traits.length > 1 ? "s" : ""
    } to ${isENS ? destination : shorten(wallet, 4, 4)}`,
  });
  useMethBankStore().fetchMethBank();
  return res;
};
